<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="alertNewIVA"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title
            class="text-h5"
            style="color: red;"
          >
            Alerta de cambio de IVA dentro de los productos
          </v-card-title>
          <v-card-text>
            <p>Debido a nuevas disposiciones del gobierno ECUATORIANO, se ha implementado dentro del sistema el 15% del IMPUESTO AL VALOR AGREGADO de los productos. Por este motivo es necesario revisar los precios de los productos agregados dentro del sistema con la finalidad de utilizar el IVA correspondiente.</p>
            <p style="color: orange; font-size: 12px; font-weight: bold;">Es responsabilidad única y exclusiva de cada cliente realizar el cambio correspondiente del IVA dentro de los productos. Inventarix no se responsabiliza de una utilización indebida del sistema en caso de no realizar dicha actualización.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="changeAceptAlert()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-app-bar
      id="app-bar"
      absolute
      app
      color="transparent"
      flat
      height="75"
    >
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
      >
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>

        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>

      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.name"
      />

      <v-spacer />

      <div class="mx-3" />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p
            class="view-cce"
            v-bind="attrs"
            v-on="on"
          >
            {{ companydata.certExpiration ? 'CCE: ' + companydata.certExpiration : '' }}
          </p>
        </template>
        <span>Caducidad del certificado electrónico</span>
      </v-tooltip>

      <v-select
        v-if="branchsProfileFilter.length > 0"
        style="margin-top: 30px; padding-left: 10px;"
        label="Sucursales"
        return-object
        :items="branchsProfileFilter"
        item-text='name'
        v-model="selectedBranch"
        @input="saveSelectedBranch"
      >
      </v-select>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            v-bind="attrs"
            color="green lighten-4"
            href="https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0"
            target="_blank"
            style="text-decoration:none; margin:0;"
            min-width="0"

            v-on="on"
          >
            <v-icon color="primary">
              mdi-whatsapp
            </v-icon>
          </v-btn>
        </template>
        <span>Soporte técnico</span>
      </v-tooltip>

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            style="color: white; top: 3px;"
            v-on="on"
          >
            <v-badge
              color="red"
              overlap
              bordered
            >
              <template v-slot:badge>
                <span>{{ notifications.length }}</span>
              </template>

              <v-icon color="primary">
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-list
          v-if="notifications.length === 0"
          :tile="false"
          nav
          style="padding:0px;"
        >
          <div
            style="background:lightgreen;"
          >
            <p style="padding:10px; margin:0;">
              No hay notificaciones
            </p>
          </div>
        </v-list>

        <v-list
          v-if="notifications.length > 0"
          :tile="false"
          nav
        >
          <div>
            <app-bar-item
              v-for="(n, i) in notifications"
              :key="`item-${i}`"
            >
              <v-row>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <p>{{ n.descripcion }}</p>
                </v-col>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text=""
                    @click="openLink('https://www.facebook.com/j2rtech')"
                  >
                    <v-icon>
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text=""
                    @click="openLink('https://wa.me/+593984530998')"
                  >
                    <v-icon>
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>

      <!--    <v-btn-->
      <!--      class="ml-2"-->
      <!--      min-width="0"-->
      <!--      text-->
      <!--      to="/pages/user"-->
      <!--    >-->
      <!--      <v-icon>mdi-account</v-icon>-->
      <!--    </v-btn>-->
      <v-col
        cols="6"
        md="2"
        class="addButton"
      >
        <v-btn
          color="red"
          style="width: 100%; height: 38px; font-weight: bold;"
          @click="handleLogut"
        >
          <v-icon color="white">
            mdi-logout-variant
          </v-icon>
          <span style="padding-left: 5px;">CERRAR SESIÓN</span>
        </v-btn>
      </v-col>
      <v-col
        cols="2"
        md="2"
        class="addIcon"
        style="text-align: right;"
      >
        <v-icon
          color="red"
          @click="handleLogut"
        >
          mdi-logout-variant
        </v-icon>
      </v-col>
    </v-app-bar>
  </div>
</template>

<script>
  import '@/sass/overrides.sass'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import moment from 'moment'

  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      alertNewIVA: false,
      currentUserId: '',
      selectedBranch: {
        id: -2,
        name: 'Todas',
        secuenciales: null,
      },
      loginTime: {
        date: '',
        time: '',
      },
      companydata: {
        contribuyenteEspecial: '',
        obligadoContabilidad: false,
        regimenMicroempresas: false,
        regimenRimpeEmprendedor: false,
        regimenGeneral: false,
        regimenRimpeNegocio: false,
        agenteRetencion: 'NO APLICA',
        ambiente: {
          codigo: 2,
          texto: 'Producción',
        },
        dirEstablecimiento: '',
        certName: '',
        certPassword: '',
        certExpiration: '',
        sendSri: false,
        calificacionArtesanal: '',
      },
    }),

    computed: {
      ...mapState(['drawer']),
      notifications () {
        return this.$store.state.notifications.notifications
      },
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
      branchsProfileFilter () {
        return this.$store.state.profile.branchsProfileFilter
      },
    },
    watch: {
      companyDataLoad () {
        this.companydata = this.companyDataLoad
      },
    },
    created () {
      this.loadInitData()
    },
    methods: {
      changeAceptAlert () {
        this.alertNewIVA = false
        this.$store.dispatch('profile/setAcceptIva', true)
      },
      normalFilterData (currentUserUid) {
        this.$store.dispatch('product/loadProducts', null)
        this.$store.dispatch('product/loadCombos', null)
        const dataUser = {
          idUser: currentUserUid,
          branch: null,
        }
        this.$store.dispatch('financial/loadEntryArr', dataUser)
        this.$store.dispatch('financial/loadEgressArr', dataUser)
        this.$store.dispatch('factura/loadFacturaSale', null)
        this.$store.dispatch('factura/loadSelfConsumption', null)
        this.$store.dispatch('factura/loadFacturaSale2', null)
        this.$store.dispatch('factura/loadNoteSale', null)
        this.$store.dispatch('factura/loadNoteSale2', null)
        this.$store.dispatch('factura/loadFacturaPurchse', null)
        this.$store.dispatch('factura/loadNotePurchse', null)
      },
      saveSelectedBranch () {
        const currentUser = firebase.auth().currentUser
        const currentUserUid = currentUser.uid

        const branchIndex = this.branchsProfileFilter.findIndex(branch => branch.id === this.selectedBranch.id)
        const dataBranch = {
          userUid: currentUserUid,
          branchIndex: branchIndex,
        }
        this.$store.dispatch('profile/setBranchChangedData', dataBranch)
        let selectedBranchSet = {
          id: -1,
          name: 'Matriz',
          secuenciales: null,
        }
        if (this.selectedBranch) {
          if (this.selectedBranch.id.toString() !== '-2') {
            selectedBranchSet = this.selectedBranch
            this.$store.dispatch('product/loadProducts', selectedBranchSet)
            this.$store.dispatch('product/loadCombos', selectedBranchSet)
            const dataUser = {
              idUser: currentUserUid,
              branch: this.selectedBranch,
            }
            this.$store.dispatch('financial/loadEntryArr', dataUser)
            this.$store.dispatch('financial/loadEgressArr', dataUser)
            this.$store.dispatch('factura/loadFacturaSale', selectedBranchSet)
            this.$store.dispatch('factura/loadSelfConsumption', selectedBranchSet)
            this.$store.dispatch('factura/loadFacturaSale2', selectedBranchSet)
            this.$store.dispatch('factura/loadNoteSale', selectedBranchSet)
            this.$store.dispatch('factura/loadNoteSale2', selectedBranchSet)
            this.$store.dispatch('factura/loadFacturaPurchse', selectedBranchSet)
            this.$store.dispatch('factura/loadNotePurchse', selectedBranchSet)
          } else {
            this.normalFilterData(currentUserUid)
          }
          this.$store.dispatch('profile/setDataBranchSelected', selectedBranchSet)
          this.$store.dispatch('profile/setDataBranchSelectedFilter', this.selectedBranch)
          localStorage.setItem('selectedBranchLocal', JSON.stringify(selectedBranchSet))
          return
        } else {
          this.normalFilterData(currentUserUid)
        }
        this.$store.dispatch('profile/setDataBranchSelected', selectedBranchSet)
        this.$store.dispatch('profile/setDataBranchSelectedFilter', selectedBranchSet)
        localStorage.setItem('selectedBranchLocal', JSON.stringify(selectedBranchSet))
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
        handleLogut () {
          this.dialog = false
          firebase.auth().signOut().then(() => {
            this.$store.dispatch('financial/clearData')
            localStorage.clear()
            this.$router.push('/')
          })
        },
        async loadInitData () {
          const currentUser = firebase.auth().currentUser
          let currentUserUid = currentUser.uid
          this.currentUserId = currentUser.uid
          this.$store.dispatch('user/setSelectedUserUid', currentUser.uid)
          await this.$store.dispatch('profile/loadProfileFirst', currentUser.uid).then(async (res, reject) => {
            if (res) {
              if (res.userUidMain) {
                currentUserUid = res.userUidMain
              }
              if (res.status) {
                if (res.status === 'INACTIVO') {
                  this.handleLogut()
                  return
                }
              }
              if (res.profileType) {
                this.$store.dispatch('profile/setProfileType', res.profileType)
              }
              await this.$store.dispatch('profile/loadProfile', currentUserUid).then((res, reject) => {
                if (!res.acceptNewIva) {
                  this.alertNewIVA = true
                }
                const currentUserSet = {
                  uid: currentUserUid,
                  email: res.email,
                }
                this.$store.dispatch('user/setUserData', currentUserSet)
                this.$store.dispatch('facturacion/adminLoginFac')
                this.$store.dispatch('suscriptionPlan/loadPlanSuscription')

                let userSend = this.$store.state.user.user.email
                if (userSend.length > 20) {
                  userSend = userSend.substr(0, 19)
                }
                this.$store.dispatch('facturacion/userLoginFac', [userSend, res.valuidfac]).then((res, reject) => {
                })
              })
            }
          })
          const selectedBranchLocal = localStorage.getItem('selectedBranchLocal')
          this.selectedBranch = this.branchsProfileFilter.length > 0 ? JSON.parse(selectedBranchLocal) : null
          this.saveSelectedBranch()
          this.$store.dispatch('product/loadProducts', this.selectedBranch)
          this.$store.dispatch('product/loadCombos', this.selectedBranch)
          this.$store.dispatch('provider/loadProviders')
          this.$store.dispatch('provider/loadProvidersExternos')
          this.$store.dispatch('client/loadClients')
          this.$store.dispatch('ivaretenciones/cargarValorIva')
          this.$store.dispatch('ivaretenciones/cargarListaRetenciones')
          this.$store.dispatch('proforma/loadProforma')

          this.$store.dispatch('factura/loadFacturaSale', this.selectedBranch)
          this.$store.dispatch('factura/loadSelfConsumption', this.selectedBranch)
          this.$store.dispatch('factura/loadFacturaSale2', this.selectedBranch)
          this.$store.dispatch('factura/loadNoteSale', this.selectedBranch)
          this.$store.dispatch('factura/loadNoteSale2', this.selectedBranch)
          this.$store.dispatch('factura/loadFacturaPurchse', this.selectedBranch)
          this.$store.dispatch('factura/loadNotePurchse', this.selectedBranch)

          this.$store.dispatch('table/loadOpenTableLst')
          this.$store.dispatch('ivaretenciones/cargarRetencionesIr')
          this.$store.dispatch('ivaretenciones/cargarRetencionesIva')
          this.$store.dispatch('advance/loadAdvances')
          this.$store.dispatch('receipt/loadReceipts')
          this.$store.dispatch('receipt/loadSecuencial')
          if (currentUser) {
            const dataUser = {
              idUser: currentUserUid,
              branch: this.selectedBranch,
            }
            this.$store.dispatch('financial/loadEntryArr', dataUser)
            this.$store.dispatch('financial/loadEgressArr', dataUser)
            this.loginTime.date = moment().format('MMM Do YY')
            this.loginTime.time = moment().format('h:mm:ss a')
            this.$store.dispatch('user/saveLastLog', [this.loginTime, currentUserUid])
          }
          this.$store.dispatch('facturacion/loadCompanyData')
          this.$store.dispatch('facturacion/loadCompanyData2')
          this.$store.dispatch('facturacion/loadelEctronicBillingTables')
          this.$store.dispatch('profile/getLstProfiles')
          this.$store.dispatch('user/loadUsrLst')
        },
      }),
    },
  }
</script>
<style>
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProRegular', sans-serif !important;
}
h3{
  font-family: 'ceraProBold', sans-serif !important;
}
label {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-dialog > .v-card > .v-card__text {
  /* text-align: center; */
  font-family: "ceraProRegular", sans-serif !important;
}
.success-btn {
  background: #279c6a;
  color:white !important;
}
.tooltips{
  font-family: "ceraProRegular", sans-serif !important;
}
.v-card__title, .v-toolbar__title {
  font-family: 'ceraProBlack', sans-serif !important;
  font-size: 24px !important;
}
.v-list-item__content {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-application p, .v-application a {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-stepper__step {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-alert__content {
  font-family: 'ceraProRegular', sans-serif !important;
}
.theme--light.v-input{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-data-footer__select{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-application .font-weight-light{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-snack__content{
  font-family: 'ceraProMedium', sans-serif !important;
}
.v-btn__content {
  font-family: 'ceraProMedium', sans-serif !important;
}
.v-data-table > .v-data-table__wrapper > table{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-tabs--icons-and-text > .v-tabs-bar .v-tab{
  font-family: 'ceraProBlack', sans-serif !important;
}
.view-cce {
  color: orange;
  font-size: 16px;
  font-weight: bold;
  margin-top: 17px;
}
  @media screen and (max-width: 500px) {
    .addButton {
      display: none;
    }
    .view-cce {
      display: none;
    }
  }
  @media screen and (min-width: 500px) {
    .addIcon {
      display: none;
    }
  }
</style>
